import { styled } from "styled-components";

import Icon from "components/Icon";
import { default as BaseLanguagePicker } from "components/LanguagePicker";
import Link from "components/Link";
import { styledLink } from "components/Link/Link.styled";
import Section from "components/Section";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyM, styleLabelM } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap}
    align-items: flex-start;
    color: var(--text-inverted);
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 2.625rem;
    padding-top: 3.5rem;
    row-gap: 3.5rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: 4.375rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 6.125rem;
    }
`;

export const LogoWrapper = styled.div`
    grid-column: 4 / span 1;
    grid-row: 1 / span 2;
    justify-self: right;

    ${MQ.FROM_M} {
        grid-column: 11 / span 2;
    }

    ${MQ.FROM_L} {
        grid-row: 1;
    }
`;

export const Logo = styled(Icon)`
    height: 3.5rem;
`;

export const Links = styled.div`
    ${styleBodyM};
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    grid-column: 1 / span 3;
    place-items: flex-start flex-start;

    ${MQ.FROM_M} {
        grid-column: auto / span 4;
        grid-row: 1 / span 2;

        &:nth-child(2) {
            grid-row: 1;
        }
    }

    ${MQ.FROM_L} {
        grid-column: auto / span 3;
        grid-row: 1;
    }

    ${MQ.FROM_XL} {
        grid-column: auto / span 2;
    }
`;

export const LinkItem = styled(Link)`
    border-bottom: 1px solid var(--color-white-o00);
    color: var(--text-inverted);

    &:hover {
        border-bottom: 1px solid var(--text-inverted);
    }
`;

export const LinksHeading = styled.div`
    ${styleLabelM};
    color: var(--text-on-blue-secondary);
    margin-bottom: 0.875rem;
`;

export const SocialLinks = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 3;
    row-gap: 3.5rem;

    ${MQ.FROM_M} {
        grid-column: 5 / span 4;
        grid-row: 2;
    }

    ${MQ.FROM_L} {
        grid-column: auto / span 3;
        grid-row: 1;
    }

    ${MQ.FROM_XL} {
        grid-column: auto / span 2;
    }
`;

export const SocialLinksItems = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0.875rem;
    place-items: flex-start;

    ${MQ.FROM_L} {
        margin-top: 3.5rem;
    }
`;

export const SocialLinksColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    place-items: flex-start;

    ${SocialLinksItems} {
        ${MQ.FROM_L} {
            margin-top: 0;
        }
    }
`;

export const SocialLink = styled(Link)`
    border: none;
    color: var(--text-inverted);

    &:hover {
        color: var(--text-inverted-secondary);
    }
`;

export const LanguagePicker = styled(BaseLanguagePicker)`
    grid-column: 1 / span 4;
    justify-self: center;

    ${MQ.FROM_M} {
        grid-column: 1 / span 12;
    }

    ${MQ.FROM_L} {
        justify-self: flex-start;
        grid-column: 1 / span 6;
    }

    ${MQ.FROM_XL} {
        grid-column: 1 / span 4;
    }
`;

export const BottomContainer = styled(GridContainer)`
    align-items: center;
    padding-bottom: 1.3125rem;
    padding-top: 1.3125rem;
`;

export const BottomInnerContainer = styled.div`
    ${styleBodyM};
    ${columnGap};
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(4, [col-start] 1fr);
    place-items: center flex-start;
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const BottomSection = styled(Section)`
    align-items: center;
    color: var(--text-inverted);

    ${LanguagePicker} {
        display: none;
    }

    ${MQ.FROM_L} {
        ${LanguagePicker} {
            display: block;
        }

        background-color: var(--overlay-on-blue-darken);
    }
`;

export const BottomSectionResponsive = styled(BottomSection)`
    background-color: var(--overlay-on-blue-darken);

    ${LanguagePicker} {
        display: block;
    }

    ${MQ.FROM_L} {
        display: none;
    }
`;

export const PersonalIntegrityLinks = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.875rem 1.75rem;
    grid-column: 1 / span 4;
    justify-self: center;

    ${MQ.FROM_M} {
        flex-direction: row;
        grid-column: 1 / span 12;
        justify-self: flex-end;
    }

    ${MQ.FROM_L} {
        align-items: flex-start;
        grid-column: 7 / span 6;
        justify-self: flex-start;
        text-align: right;
    }

    ${MQ.FROM_XL} {
        grid-column: 5 / span 8;
    }
`;

export const PersonalIntegrityLink = styled(Link)`
    color: var(--text-inverted-secondary);

    span {
        border-bottom: 1px solid var(--color-white-o00);
    }

    &:hover {
        span {
            border-bottom: 1px solid var(--text-inverted);
            color: var(--text-inverted);
        }
    }
`;

export const UpdateCookieConsent = styled.button`
    all: unset;
    ${styledLink};
    border-bottom: 0;
    color: var(--text-inverted-secondary);
    text-align: right;

    span {
        border-bottom: 1px solid var(--color-white-o00);
    }

    &:hover {
        span {
            border-bottom: 1px solid var(--text-inverted);
            color: var(--text-inverted);
        }
    }

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }
`;
