import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import LanguagePickerProps from "./LanguagePickerProps";

const LanguagePicker = loadable(
    (): Promise<DefaultComponent<LanguagePickerProps>> =>
        import(/* webpackChunkName: "LanguagePicker" */ "./LanguagePicker"),
);
const LanguagePickerLoader = (props: LanguagePickerProps): ReactElement => (
    <LanguagePicker {...props} />
);
export default LanguagePickerLoader;
