import React, { ReactElement } from "react";
import isEqual from "react-fast-compare";

import Icon from "components/Icon";
import Section from "components/Section";
import { useBrand, useBreakpoint } from "hooks";
import { A11y } from "style/components/A11Y";
import triggerUpdateCookieConsent from "utils/triggerUpdateCookieConsent";

import {
    Container,
    LogoWrapper,
    Logo,
    Links,
    LinksHeading,
    SocialLinks,
    LanguagePicker,
    LinkItem,
    SocialLink,
    BottomContainer,
    BottomSection,
    PersonalIntegrityLinks,
    PersonalIntegrityLink,
    BottomInnerContainer,
    UpdateCookieConsent,
    BottomSectionResponsive,
    SocialLinksColumn,
    SocialLinksItems,
} from "./Footer.styled";
import FooterProps from "./FooterProps";

const Footer = ({
    key = "footerContent",
    logoTitle,
    navigation,
    socialLinks,
    socialLinksColumns,
    sites,
    personalIntegrityLinks,
    columnsLimit = 2,
    l18n,
}: FooterProps): ReactElement => {
    const onUpdateCookieConsentClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        triggerUpdateCookieConsent();
    };
    const breakpoint = useBreakpoint();

    let logo: string;
    switch (useBrand()) {
        case "soderbergpartnersgroup":
            switch (breakpoint) {
                case "XL":
                case "L":
                case "M":
                    logo = "spgroupSolid56";
                    break;
                default:
                    logo = "";
            }
            break;
        case "sphab":
            switch (breakpoint) {
                case "XL":
                case "L":
                case "M":
                    logo = "sphabSolid56";
                    break;
                default:
                    logo = "";
            }
            break;
        case "claimslink":
            logo = "claimslinkSmallSolid28";
            break;
        case "spuk":
        default:
            logo = "logoSmallSolid56";
    }

    return (
        <div role="contentinfo">
            <Section key={key} theme="blue" alignItems="center">
                <Container>
                    {navigation?.items
                        ?.slice(0, columnsLimit)
                        .map((navCol, index) => (
                            <Links key={`FooterCol-${index}`}>
                                <LinksHeading>
                                    {navCol.displayName}
                                </LinksHeading>
                                {navCol.items?.map((navItem, index) => (
                                    <div key={`FooterLink-${index}`}>
                                        <LinkItem
                                            url={navItem.url}
                                            title={
                                                (navItem.properties
                                                    ?.title as string) ||
                                                navItem.displayName ||
                                                ""
                                            }
                                        >
                                            {navItem.displayName}
                                        </LinkItem>
                                    </div>
                                ))}
                            </Links>
                        ))}
                    {(socialLinks || socialLinksColumns) && (
                        <SocialLinks key={`SocialLinks`}>
                            {socialLinks?.items &&
                                socialLinks.items.length > 0 && (
                                    <SocialLinksItems>
                                        {socialLinks.items.map(
                                            (navItem, index) => (
                                                <SocialLink
                                                    key={`FooterLink-${index}`}
                                                    url={navItem.url}
                                                    title={
                                                        (navItem.properties
                                                            ?.title as string) ||
                                                        navItem.displayName ||
                                                        ""
                                                    }
                                                >
                                                    <Icon
                                                        icon={
                                                            navItem.properties
                                                                ?.icon as string
                                                        }
                                                    />
                                                    <A11y>
                                                        {navItem.displayName}
                                                    </A11y>
                                                </SocialLink>
                                            ),
                                        )}
                                    </SocialLinksItems>
                                )}
                            {socialLinksColumns?.items?.map((navCol, index) => (
                                <SocialLinksColumn
                                    key={`FooterSocialCol-${index}`}
                                >
                                    {navCol.displayName && (
                                        <LinksHeading>
                                            {navCol.displayName}
                                        </LinksHeading>
                                    )}
                                    {navCol.items && (
                                        <SocialLinksItems>
                                            {navCol.items.map(
                                                (navItem, index) => (
                                                    <SocialLink
                                                        key={`FooterLink-${index}`}
                                                        url={navItem.url}
                                                        title={
                                                            (navItem.properties
                                                                ?.title as string) ||
                                                            navItem.displayName ||
                                                            ""
                                                        }
                                                    >
                                                        <Icon
                                                            icon={
                                                                navItem
                                                                    .properties
                                                                    ?.icon as string
                                                            }
                                                        />
                                                        <A11y>
                                                            {
                                                                navItem.displayName
                                                            }
                                                        </A11y>
                                                    </SocialLink>
                                                ),
                                            )}
                                        </SocialLinksItems>
                                    )}
                                </SocialLinksColumn>
                            ))}
                        </SocialLinks>
                    )}
                    {logo && (
                        <LogoWrapper>
                            <Logo
                                key={`Logo-${logo}`}
                                icon={logo}
                                aria-label={logoTitle}
                            />
                        </LogoWrapper>
                    )}
                </Container>
                {sites && sites.length > 0 && (
                    <BottomSectionResponsive>
                        <BottomContainer>
                            <BottomInnerContainer>
                                <LanguagePicker sites={sites} />
                            </BottomInnerContainer>
                        </BottomContainer>
                    </BottomSectionResponsive>
                )}
                <BottomSection>
                    <BottomContainer>
                        <BottomInnerContainer>
                            {sites && sites.length > 0 && (
                                <LanguagePicker sites={sites} />
                            )}

                            <PersonalIntegrityLinks
                                key={`PersonalIntegrityLinks`}
                            >
                                {personalIntegrityLinks ? (
                                    personalIntegrityLinks.items?.map(
                                        (navItem, index) => (
                                            <div
                                                key={`PersonalIntegrityLink-${index}`}
                                            >
                                                <PersonalIntegrityLink
                                                    url={navItem.url}
                                                    title={
                                                        (navItem.properties
                                                            ?.title as string) ||
                                                        navItem.displayName ||
                                                        ""
                                                    }
                                                    hoverSpan={true}
                                                >
                                                    <span>
                                                        {navItem.displayName}
                                                    </span>
                                                </PersonalIntegrityLink>
                                            </div>
                                        ),
                                    )
                                ) : (
                                    <></>
                                )}
                                <UpdateCookieConsent
                                    key={`UpdateCookieConsent`}
                                    onClick={onUpdateCookieConsentClick}
                                    title={l18n?.updateCookieConsent}
                                >
                                    <span>
                                        {l18n?.updateCookieConsent ||
                                            "Update cookie consent"}
                                    </span>
                                </UpdateCookieConsent>
                            </PersonalIntegrityLinks>
                        </BottomInnerContainer>
                    </BottomContainer>
                </BottomSection>
            </Section>
        </div>
    );
};

export default React.memo(Footer, isEqual);
